import { ApolloClient } from '@apollo/client/core/index.js';

import { image } from '@/utilities/graphql/image'
export function getImage (client: ApolloClient<object>, alias: String) {
  return new Promise ((resolve) => {
      client.query({
        query: image,
        variables: {
          alias: alias
        },
      }).then((data: any) => {
        if(data.data.image) {
          resolve(data.data.image[0])
        }
      });
    }
  )
}

import { banner, bannerObj } from '@/utilities/graphql/banner'
export function getBanner (client: ApolloClient<object>, alias: String): Promise<bannerObj> {
  return new Promise ((resolve) => {
      client.query({
        query: banner,
        variables: {
          alias: alias
        },
      }).then((data: any) => {
        if(data.data.banner.length) {
          let returnBanner = {
            ...data.data.banner[0],
            bannerMainText: data.data.banner[0].bannerMainText ? data.data.banner[0].bannerMainText.html : null,
            bannerSubtext: data.data.banner[0].bannerSubtext ? data.data.banner[0].bannerSubtext.html : null,
            image: data.data.banner[0].image.urls ? data.data.banner[0].image.urls[0] : null,
            mobileImage: data.data.banner[0].mobileImage.urls[0] ? data.data.banner[0].mobileImage.urls[0] : null
          };
          resolve(returnBanner)
        }
      });
    }
  )
}

import { contentBlock } from '@/utilities/graphql/contentblock'
export function getContentBlock (client: ApolloClient<object>, alias: String) {
  return new Promise ((resolve) => {
      client.query({
        query: contentBlock,
        variables: {
          alias: alias
        },
      }).then((data: any) => {
        if(data.data.contentBlock) {
          resolve(data.data.contentBlock[0])
        }
      });
    }
  )
}

import { testimonial } from '@/utilities/graphql/testimonial'
export function getTestimonial (client: ApolloClient<object>) {
  return new Promise ((resolve) => {
      client.query({
        query: testimonial,
      }).then((data: any) => {
        if(data.data.testimonial) {
          resolve(data.data.testimonial)
        }
      });
    }
  )
}

import { promoCount, promotions, promotion } from '@/utilities/graphql/promotion'
export function isPromotionAvailable (client: ApolloClient<object>) {
  return new Promise ((resolve) => {
      client.query({
        query: promoCount,
      }).then((data: any) => {
        if(data.data.promotion) {
          const promotions = data.data.promotion.filter((item: any) => item.display);
          resolve(promotions.length > 0)
        }
      });
    }
  )
}
export function getPromotionList (client: ApolloClient<object>) {
  return new Promise ((resolve) => {
      client.query({
        query: promotions,
      }).then((data: any) => {
        if(data.data.promotion) {
          const promotions = data.data.promotion.filter((item: any) => item.display);
          resolve(promotions)
        }
      });
    }
  )
}
export function getPromotion (client: ApolloClient<object>, alias: String) {
  return new Promise ((resolve) => {
      client.query({
        query: promotion,
        variables: {
          alias: alias
        },
      }).then((data: any) => {
        if(data.data.promotion) {
          resolve(data.data.promotion[0])
        }
      });
    }
  )
}

import { popup, popupObj } from '@/utilities/graphql/popup'
export function getPopup (client: ApolloClient<object>) {
  return new Promise ((resolve) => {
      client.query({
        query: popup,
      }).then((data: any) => {
        if(data.data.popup) {
          const popup = data.data.popup.filter((item: any) => item.active);
          resolve(popup[0])
        }
      });
    }
  )
}

import { articleCategory } from '@/utilities/graphql/article'
export function getArticleCategory (client: ApolloClient<object>) {
    return new Promise ((resolve) => {
        client.query({
          query: articleCategory
        }).then((resp: any) => {
          if(resp.data.taxonomy) {
            resolve(resp.data.taxonomy[0].taxonomy.contentItems)
          }
        });
      }
    )
}

import { articleList } from '@/utilities/graphql/article'
export function getArticleList (client: ApolloClient<object>) {
    return new Promise ((resolve) => {
        client.query({
          query: articleList
        }).then((resp: any) => {
          if(resp.data.article) {
            resolve(resp.data.article)
          }
        });
      }
    )
}

import { article } from '@/utilities/graphql/article'
export function getArticle (client: ApolloClient<object>, alias: String) {
    return new Promise ((resolve) => {
        client.query({
          query: article,
          variables: {
            alias: alias
          },
        }).then((resp: any) => {
          if(resp.data.article) {
            resolve(resp.data.article[0])
          }
        });
      }
    )
}

import { newsCategory } from '@/utilities/graphql/news'
export function getNewsCategory (client: ApolloClient<object>) {
    return new Promise ((resolve) => {
        client.query({
          query: newsCategory
        }).then((resp: any) => {
          if(resp.data.taxonomy) {
            resolve(resp.data.taxonomy[0].taxonomy.contentItems)
          }
        });
      }
    )
}

import { newsList } from '@/utilities/graphql/news'
export function getNewsList (client: ApolloClient<object>) {
    return new Promise ((resolve) => {
        client.query({
          query: newsList
        }).then((resp: any) => {
          if(resp.data.newsArticle) {
            resolve(resp.data.newsArticle)
          }
        });
      }
    )
}

import { newsArticle } from '@/utilities/graphql/news'
export function getNewsArticle (client: ApolloClient<object>, alias: String) {
    return new Promise ((resolve) => {
        client.query({
          query: newsArticle,
          variables: {
            alias: alias
          },
        }).then((resp: any) => {
          if(resp.data.newsArticle) {
            resolve(resp.data.newsArticle[0])
          }
        });
      }
    )
}

import { valueAddedServicesList } from '@/utilities/graphql/valueaddedservice'
export function getValueAddedServicesList (client: ApolloClient<object>) {
    return new Promise ((resolve) => {
        client.query({
          query: valueAddedServicesList
        }).then((resp: any) => {
          if(resp.data.valueAddedService) {
            resolve(resp.data.valueAddedService)
          }
        });
      }
    )
}

import { valueAddedService } from '@/utilities/graphql/valueaddedservice'
export function getValueAddedService (client: ApolloClient<object>, alias: String) {
    return new Promise ((resolve) => {
        client.query({
          query: valueAddedService,
          variables: {
            alias: alias
          },
        }).then((resp: any) => {
          if(resp.data.valueAddedService) {
            resolve(resp.data.valueAddedService[0])
          }
        });
      }
    )
}

import { wheelBrand, wheelList, wheel } from '@/utilities/graphql/wheel'
export function getWheelBrands (client: ApolloClient<object>) {
    return new Promise ((resolve) => {
        client.query({
          query: wheelBrand
        }).then((resp: any) => {
          if(resp.data.taxonomy) {
            resolve(resp.data.taxonomy[0].taxonomy.contentItems)
          }
        });
      }
    )
}
export function getWheelList (client: ApolloClient<object>, brandSearch: string) {
    return new Promise ((resolve) => {
        client.query({
          query: wheelList
        }).then((resp: any) => {
          if(resp.data.wheel) {
            const list = resp.data.wheel.reduce((filtered: any, item: any) => {
              const brand = item.brand.termContentItems[0].alias.alias;
              const slug = item.alias.alias;
              const image = item.thumbnailImage.urls[0];
              if (brand == brandSearch) {
                filtered.push({
                  title: item.displayText,
                  image: {src: image},
                  link: {name: 'WheelDetail', params: {brand: brand, slug: slug}}
                })
              }
              return filtered;
            }, [])
            resolve(list)
          }
        });
      }
    )
}
export function getWheel (client: ApolloClient<object>, alias: String) {
    return new Promise ((resolve) => {
        client.query({
          query: wheel,
          variables: {
            alias: alias
          },
        }).then((resp: any) => {
          if(resp.data.wheel) {
            resolve(resp.data.wheel[0])
          }
        });
      }
    )
}